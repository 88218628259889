import { useNavigate, Link } from 'react-router-dom';
import { Box, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import Icon from '../../components/Icon';
import NavigationBar from '../../components/NavigationBar';
import { TitleLabel, SubTitleLabel } from '../../components/Label';
import StyledButton from '../../components/StyledButton';
import React, { useContext } from 'react';
import { UserContext } from '../../UserContext';

const Logo = () => {
  return (
    <Box
      mt="103px"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center">
      <Icon src="/images/logo.png" width="84px" height="88px" />
      <Icon
        src="/images/logoTitle.png"
        width="100%"
        height="24px"
        sx={{ marginTop: '17px' }}
      />
    </Box>
  );
};

const Welcome = () => {
  return (
    <Box mt="16px">
      <SubTitleLabel fontSize="15px" mt="48px" align="center">
        我們提供銀行帳戶及台灣大哥大門市入金，支援新台幣買賣BTC及ETH。立即加入，享受讓您感到便利又安心的服務！
      </SubTitleLabel>
    </Box>
  );
};

const BottomPanel = () => {
  const theme = useTheme();
  const {
    palette: {
      primary: { main },
    },
  } = theme;

  return (
    <Stack spacing={3.3} m="152px 0 30px 0">
      <StyledButton
        variant="contained"
        fullWidth
        disableElevation
        sx={{
          padding: '16px 0',
          height: '48px',
          boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.15)',
        }}
        component={Link}
        to="/signIn">
        登入
      </StyledButton>
      <StyledButton
        variant="outlined"
        fullWidth
        disableElevation
        sx={{
          padding: '16px 0',
          height: '48px',
          border: `solid 2px ${main}`,
          color: main,
        }}
        component={Link}
        to="/signUp"
        state={{ previousPage: '/landing' }}>
        註冊
      </StyledButton>
    </Stack>
  );
};

export default function LandingPage() {
  const { setTabIndex, setNavTitle } = useContext(UserContext);

  const navigate = useNavigate();
  const handleBack = () => {
    setTabIndex(0);
    setNavTitle('OP加密資產存摺');
    navigate('/');
  };

  return (
    <>
      <NavigationBar noShadow leftButtonHandler={handleBack} />
      <Logo />
      <Welcome />
      <BottomPanel />
    </>
  );
}
