import { useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { CoinNumberFormat } from '../../utils/CoinNumberFormat';
import moment from 'moment/moment';
import TradeDetailPanel from '../../components/asset/TradeDetailPanel';
import StyledButton from '../../components/StyledButton';
import { navigationBarHeight } from '../../components/Layout';
import { Box } from '@mui/material';
import BottomPanel from '../../components/BottomPanel';
import CopyToClipboardButton from '../../components/CopyToClipboardButton';
import { TitleLabel } from '../../components/Label';

const fee = 18;

const sxContainer = {
  pt: `${navigationBarHeight}px`,
  pl: '16px',
  pr: '16px',
};

const withdrawRecordStatus = {
  open: '處理中',
  fbo_confirmed: '處理中',
  txt_done: '處理中',
  refunding: '處理中',
  processing: '處理中',
  succeed: '已放款',
  rejected: '失敗',
};

const formatUnixTimestamp = (timestamp) => {
  return moment.unix(timestamp).format('YYYY-MM-DD HH:mm A');
};

const InvoiceButton = ({ records }) => {
  return (
    <StyledButton
      variant="contained"
      fullWidth
      disableElevation
      sx={{ height: '48px', fontSize: '15px' }}
      component={Link}
      to="/invoiceInfo"
      state={{ order: records }}>
      發票
    </StyledButton>
  );
};

const BottomButton = () => {
  return (
    <BottomPanel>
      <StyledButton
        variant="contained"
        fullWidth
        disableElevation
        component={Link}
        to="/assetHistory/withdraw"
        state={{ recordType: 2 }}>
        查看提領紀錄
      </StyledButton>
    </BottomPanel>
  );
};

export default function BankWithdrawDonePage() {
  const { state, pathname } = useLocation();
  const tradeType = pathname.split('/')[1];
  const { title, bankRecord, back } = state;
  const hasInvoiceNo = bankRecord?.invoiceInfo?.invoiceNo;
  const createdTime = bankRecord?.createdTime;
  const txId = bankRecord?.txId;
  const amount = bankRecord?.amount;
  const status = bankRecord?.status;
  const [openCopyMessage, setOpenCopyMessage] = useState(false);

  const handleClickCopy = () => {
    navigator.clipboard.writeText(txId);
    setOpenCopyMessage((prev) => !prev);
  };

  const withdrawBankRecords = [
    {
      title: '提領紀錄號碼',
      content: (
        <Box display="flex" alignItems="center">
          <TitleLabel fontSize="12px" color="#6C757D">
            {txId}
          </TitleLabel>
          <CopyToClipboardButton
            openCopyMessage={openCopyMessage}
            onClick={handleClickCopy}
            message="提領紀錄號碼已複製"
          />
        </Box>
      ),
    },
    {
      title: '日期',
      content: formatUnixTimestamp(createdTime),
    },
    {
      title: '提領方式',
      content: '銀行轉帳',
      fontWeight: 'bold',
    },
    {
      title: '提領金額',
      content: (
        <>
          <CoinNumberFormat value={amount} /> TWD
        </>
      ),
    },
    { title: '手續費', content: `${fee} TWD` },
    {
      title: '實際入帳金額',
      content: (
        <>
          <CoinNumberFormat value={amount - fee} /> TWD
        </>
      ),
      fontWeight: 'bold',
    },
    {
      title: '狀態',
      content: withdrawRecordStatus[status],
    },
  ];

  return (
    <Box sx={sxContainer}>
      <TradeDetailPanel
        title={title}
        records={withdrawBankRecords}
        tradeType={tradeType}
        originFrom={state?.originFrom ? state?.originFrom : ''}
      />
      {hasInvoiceNo && <InvoiceButton records={bankRecord} />}
      {back && <BottomButton />}
    </Box>
  );
}
