import React, { useState, useContext } from 'react';
import { styled } from '@mui/system';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment/moment';
import { Paper, Button, Box, Tabs, Tab } from '@mui/material';
import NavigationBar from '../../components/NavigationBar';
import { navigationBarHeight } from '../../components/Layout';
import Separator from '../../components/Separator';
import AssetRecordCell from '../../components/asset/AssetRecordCell';
import FundRecordCell from '../../components/asset/FundRecordCell';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CurrencyMenu from '../../components/asset/CurrencyMenu';
import { UserContext } from '../../UserContext';
import { OrderAPI } from '../../apis/OrderAPI';
import { UserAPI } from '../../apis/UserAPI';
import { useEffectOnce } from '../../utils/UseEffectOnce';
import { useLocation } from 'react-router-dom';

const menuBarHeight = 49;
const filterButtonHeight = 47;

const sxContainer = {
  pt: `${navigationBarHeight + menuBarHeight + filterButtonHeight}px`,
};

const sxMenu = {
  position: 'fixed',
  top: `${navigationBarHeight - 1}px`,
  left: 0,
  right: 0,
  height: `${menuBarHeight + filterButtonHeight}px`,
  zIndex: (theme) => theme.zIndex.drawer,
};

const sxContainerWithoutFilter = {
  pt: `${navigationBarHeight + menuBarHeight}px`,
};

const sxMenuWithoutFilter = {
  position: 'fixed',
  top: `${navigationBarHeight - 1}px`,
  left: 0,
  right: 0,
  height: `${menuBarHeight}px`,
  zIndex: (theme) => theme.zIndex.drawer,
};

const FilterButton = styled(Button)(({ theme }) => ({
  color: '#000',
  fontSize: '15px',
  fontWeight: 'medium',
}));

function a11yProps(index) {
  return {
    id: `editor-tab-${index}`,
    'aria-controls': `editor-tabpanel-${index}`,
  };
}

export default function AssetHistoryPage() {
  const { state } = useLocation();
  const { user, setLoading, setTabIndex, setNavTitle } =
    useContext(UserContext);
  const params = useParams();
  const [orders, setOrders] = useState([]);
  const [deposits, setDeposits] = useState([]);
  const [withdraws, setWithdraws] = useState([]);
  const [recordType, setRecordType] = useState(
    params.defaultFilter === 'withdraw'
      ? 2
      : params.defaultFilter === 'deposit'
      ? 1
      : 0,
  );
  const [filter, setFilter] = useState(
    params.defaultFilter === 'withdraw' || params.defaultFilter === 'deposit'
      ? 'ALL'
      : params.defaultFilter,
  );
  const [showCurrencyMenu, setShowCurrencyMenu] = useState(false);
  const navigate = useNavigate();
  const originFrom = state?.from
    ? state?.from
    : state?.originFrom
    ? state?.originFrom
    : '';

  useEffectOnce(() => {
    async function fetchAssetsData() {
      if (user) {
        setLoading(true);

        const _orders = await OrderAPI.getOrders();
        setOrders(_orders);

        const _deposits = await UserAPI.getDepositRecords();
        setDeposits(_deposits);

        const _withdraws = await UserAPI.getWithdrawRecords();
        setWithdraws(_withdraws);

        setLoading(false);
      }
    }

    fetchAssetsData();
  });

  const handleBack = () => {
    if (originFrom.indexOf('/market') >= 0) {
      navigate(originFrom);
    } else {
      setTabIndex(1);
      setNavTitle('我的資產');
      navigate('/asset');
    }
  };

  const handleChange = (event, newValue) => {
    const path =
      newValue === 0 ? filter : newValue === 1 ? 'deposit' : 'withdraw';
    navigate(`/assetHistory/${path}`, { state: { originFrom: originFrom } });
    setRecordType(newValue);
  };

  const handleOrderInfo = (order) => {
    if (
      (order.payment === 'store' || order.payment === 'bank') &&
      order.status === 'open'
    ) {
      navigate('/orderPayment', {
        state: { order, cancelOrder: true, originFrom: originFrom },
      });
    } else {
      navigate('/orderInfo', { state: { order, originFrom: originFrom } });
    }
  };

  const handleWithdrawInfo = (withdraw) => {
    navigate('/withdraw/bank/done', {
      state: {
        title: '提領詳情',
        bankRecord: withdraw,
        originFrom: originFrom,
      },
    });
  };

  const handleDepositInfo = (deposit) => {
    navigate(`/deposit/done`, {
      state: {
        title: '儲值詳情',
        depositRecord: deposit,
        originFrom: originFrom,
      },
    });
  };

  const navigation = () => {
    return <NavigationBar title="交易紀錄" leftButtonHandler={handleBack} />;
  };

  const menu = () => {
    const menuItems = ['訂單紀錄', '儲值紀錄', '提領紀錄'];
    return (
      <Paper sx={recordType === 0 ? sxMenu : sxMenuWithoutFilter} elevation={0}>
        <Tabs value={recordType} onChange={handleChange} variant="fullWidth">
          {menuItems.map((menuItem, index) => (
            <Tab label={menuItem} key={index} {...a11yProps({ index })} />
          ))}
        </Tabs>

        <Separator />

        {recordType === 0 && (
          <>
            <FilterButton
              fullWidth
              endIcon={<ArrowDropDownIcon />}
              sx={{
                height: `${filterButtonHeight}px`,
                textTransform: 'none',
              }}
              onClick={() => setShowCurrencyMenu(true)}>
              {filterButtonLabel}
            </FilterButton>

            <Separator />
          </>
        )}
      </Paper>
    );
  };

  const assetRecordList = () => {
    let items = [];
    for (let i = 0; i < orders.length; i++) {
      const order = orders[i];
      const symbol = getCurrencySymbolFromProductId(order.productId);
      if (filter !== 'ALL' && filter !== symbol) continue;

      items.push(
        <AssetRecordCell
          key={i}
          price={order.size}
          time={moment.unix(order.createdTime).format('YYYY/MM/DD HH:mm A')}
          status={getOrderState(order)}
          side={order.side}
          product={symbol}
          onClick={() => handleOrderInfo(order)}
        />,
      );
    }

    return <>{items}</>;
  };

  const depositRecordList = () => {
    let items = [];

    for (let i = 0; i < deposits.length; i++) {
      const deposit = deposits[i];
      items.push(
        <FundRecordCell
          key={i}
          onClick={() => handleDepositInfo(deposit)}
          type={deposit.depositMethod}
          amount={deposit.amount}
          time={moment.unix(deposit.createdTime).format('YYYY/MM/DD HH:mm A')}
          status={deposit.status}
        />,
      );
    }

    return <>{items}</>;
  };

  const withdrawRecordList = () => {
    let items = [];

    for (let i = 0; i < withdraws.length; i++) {
      const withdraw = withdraws[i];
      items.push(
        <FundRecordCell
          key={i}
          onClick={() => handleWithdrawInfo(withdraw)}
          type={withdraw.type}
          amount={withdraw.amount}
          time={moment.unix(withdraw.createdTime).format('YYYY/MM/DD HH:mm A')}
          status={withdraw.status}
        />,
      );
    }

    return <>{items}</>;
  };

  const getCurrencySymbolFromProductId = (productId) => {
    const symbols = productId.split('-');
    return symbols[0];
  };

  const getOrderState = (order) => {
    let state = 'pending';
    if (order.status === 'done') {
      if (order.doneReason === 'succeed') {
        state = 'success';
      } else if (order.doneReason === 'rejected') {
        state = 'cancel';
      } else if (order.doneReason === 'cancel') {
        state = 'cancel';
      }
    }
    return state;
  };

  const filterButtonLabel = {
    ALL: '所有幣種',
    BTC: 'Bitcoin (BTC)',
    ETH: 'Ethereum (ETH)',
    // SOL: 'Solana (SOL)',
    // USDT: 'Tether (USDT)',
    // USDC: 'USD Coin (USDC)',
  }[filter];

  const displayRecords = {
    0: assetRecordList() /* 交易記錄 */,
    1: depositRecordList() /* 儲值紀錄 */,
    2: withdrawRecordList() /* 提領紀錄 */,
  }[recordType];

  return (
    <>
      <Box sx={recordType === 0 ? sxContainer : sxContainerWithoutFilter}>
        {navigation() /* navigation bar */}
        {menu()}
        <Box padding="16px">{displayRecords}</Box>
      </Box>

      <CurrencyMenu
        showMenu={showCurrencyMenu}
        setShowMenu={setShowCurrencyMenu}
        filter={filter}
        setFilter={setFilter}
        originFrom={originFrom}
      />
    </>
  );
}
